@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

/*Header Section CSS*/

.Experience, .Projects, .Extracurriculars{
  background-color: #282c34;
  min-height: 100vh;
}

header {
  background-color: #282c34;
  min-height: 10rem;
  max-height: 10rem;
  display: flex;
  align-items: left;
  justify-content: left;
  border-bottom: 1px solid #393D45;
}

header h1 {
  width: 30%;
  float: left;
  font-weight: lighter;
  font-size: 2.4rem;
  font-family: Montserrat, sans-serif;
  padding: 2rem;
  padding-left: 5rem;
}

header a {
  font-family: Montserrat, sans-serif;
  font-weight: lighter;
  color: #DDDDDD;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

header ul {
  display: flex;
  flex: 3 1;
  margin-right: 5rem;
  margin-left: -3rem;
  width: 100%;
  float: right;
  justify-content: space-around;
}

header li {
  position: relative;
  list-style: none;
  float: left;
  margin-top: 3rem;
}

header li a {
  display: block;
  width: 100%;
  height: 1.5rem;
  padding: .7rem;
  font-size: 1.2rem;
  font-family: Source Sans Pro, sans-serif;
  color: #61dafb;
}

header li a:hover {
  transform: scale(1.2);
  color: #DDDDDD;
}

header .OnExperiencePage a{
  border-bottom: solid #61dafb;
}

header .OnProjectsPage a{
  border-bottom: solid #61dafb;
}

header .OnExtracurricularPage a{
  border-bottom: solid #61dafb;
}

.fas {
  display: none;
  color: #282c34;
  font-size: 0.5px;
}

/*Mobile Header Section CSS*/

@media only screen and (max-width: 800px) {

  header {
    background-color: #282c34;
    min-height: 10rem;
    max-height: 38rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    border-bottom: 1px solid #393D45;
  }

  .App-header ul {
    display: none;
  }

  .Mobile-header ul {
    display: flex;
    flex-direction: column;
    width: 15rem;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 3rem;
    padding: 0;
  }

  header h1 {
    width: 93vw;
    height: 50%;
    font-weight: lighter;
    font-size: 1.8rem;
    font-family: Montserrat, sans-serif;
    padding: 7vw;
    padding-right: 0;
    justify-content: space-between;
    background-color: #DDDDDD;
    margin-top: 0;
  }

  header a {
    font-family: Montserrat, sans-serif;
    font-weight: lighter;
    color: #282c34;
    text-transform: uppercase;
    text-decoration: none;
    margin: auto;
  }

  header li {
    position: relative;
    list-style: none;
    margin-top: 3rem;
    border: 1px solid #61dafb;
  }
  
  header li a {
    display: block;
    width: 100%;
    height: 1.5rem;
    padding: 0rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
    font-size: 1.2rem;
    font-family: Source Sans Pro, sans-serif;
    color: #61dafb;
    border-bottom: 0rem;
    margin-left: 0;
  }

  header .OnExperiencePage a{
    border-bottom: none;
  }

  header .OnProjectsPage a{
    border-bottom: none;
  }
  
  header .OnExtracurricularPage a{
    border-bottom: none;
  }

  .fas {
    display: inline-block;
    font-size: 30px;
    color: #282c34;
    float: left;
    margin-right: 6vw;
  }
}

@media only screen and (max-width: 350px) {
  header {
    max-width: 100vw;
  }

  header h1 {
    width: 93vw;
    height: 50%;
    font-weight: lighter;
    font-size: 1.8rem;
    font-family: Montserrat, sans-serif;
    padding: 7vw;
    padding-right: 0;
    justify-content: space-between;
    background-color: #DDDDDD;
    margin-top: 0;
  }

  header a {
    font-family: Montserrat, sans-serif;
    font-weight: lighter;
    font-size: 1.5rem;
    color: #282c34;
    text-transform: uppercase;
    text-decoration: none;
    margin: auto;
  }
}

/*Experience Section CSS*/

.ExperienceSection {
  margin-bottom: 5rem;
}

.ExperienceTile {
  background-color: #393d45;
  margin: auto;
  margin-top: 5rem;
  width: 44rem;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
  min-height: 30.5rem;
  text-align: center;
  overflow: hidden;
  color: #DDDDDD;
  font-size: 1.2rem;
  border: solid 1px #61dafb;
}

.ExperienceTile h4 {
  font-family: Quicksand, sans-serif;
  font-weight: lighter;
}

.ExperienceTile h3 {
  font-family: Quicksand, sans-serif;
  font-weight: bolder;
}

.ExperienceTile p {
  font-family: Quicksand, sans-serif;
  font-weight: lighter;
}

.ExperienceTile img {
  width: 10%;
  height: 10%;
}

.LocationAndTimeWorked {
  display: inline-block;
  margin-top: -1.4rem;
}

.LocationAndTimeWorked h4 {
  float: left;
}

#Location {
  margin-right: 2rem;
}

.LogoContainer {
  margin: auto;
}

/*Mobile Experience Section CSS*/

@media only screen and (max-width: 900px) {
  .ExperienceTile {
    background-color: #393d45;
    margin: auto;
    margin-top: 5rem;
    width: 14rem;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    min-height: 14rem;
    text-align: center;
    overflow: hidden;
    color: #DDDDDD;
    font-size: 1rem;
    border: solid 1px #61dafb;
  }

  .ExperienceTile p {
    display: none;
  }

  .LocationAndTimeWorked {
    display: inline-block;
    width: 11rem;
    word-wrap: break-word;
    text-align: center;
    font-size: 1rem;
    margin-top: .01rem;
    margin: auto;
  }
  
  .LocationAndTimeWorked h4 {
    margin-top: 0;
    width: 100%;
  }

  #Location {
    margin-right: 0;
  }  

  .LogoContainer {
    padding-bottom: 0;
  }

  .ExperienceTile img {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: -.3rem;
    border: solid 2px #61dafb;
  }
}

@media only screen and (max-width: 350px) {
  .ExperienceTile {
    background-color: #393d45;
    margin: auto;
    margin-top: 5rem;
    width: 13rem;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    min-height: 12rem;
    text-align: center;
    overflow: hidden;
    color: #DDDDDD;
    font-size: 1rem;
    border: solid 1px #61dafb;
  }

  .ExperienceTile img {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: -.3rem;
    border: solid 2px #61dafb;
  }
}

/*Project Section CSS*/

.ProjectRow {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
}

.ProjectTile {
  position: relative;
  float: left;
  height: 19rem;
  width: 35rem;
  margin-bottom: 6rem;
}

.ProjectTileImage {
  opacity: 1;
  width: 35rem;
  height: auto;
  transition: .5s ease;
  transform: translate(0, -9rem);
  -ms-transform: translate(0, -9rem);
  margin: auto;
  margin-bottom: -35%;
}

.ProjectTileInfo {
  width: 35rem;
  height: 8rem;
  transition: .5s ease;
  opacity: 0;
  text-align: center;
  color: #DDDDDD;
}

.ProjectTileInfo h4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ProjectTile:hover .ProjectTileImage {
  opacity: 0.1;
}

.ProjectTile:hover .ProjectTileInfo {
  opacity: 1;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 0vh;
}

.row {
  margin-top: 4rem;
}

/*Mobile Project Section CSS*/

@media only screen and (max-width: 1100px) {
  .ProjectRow {
    display: block;
    justify-content: space-between;
  }

  .ProjectTile {
    position: relative;
    max-width: 85vw;
    height: 50vw;
    margin-left: 2.5vw;
    margin-bottom: 4rem;
  }

  .ProjectTileImage {
    opacity: 1;
    width: 85vw;
    height: auto;
    transition: .5s ease;
    transform: translate(0, -9rem);
    -ms-transform: translate(0, -9rem); 
    margin: auto;
    border: solid 1px #61dafb;
  }

  .ProjectTileInfo {
    width: 85vw;
    height: 8rem;
    transition: .5s ease;
    opacity: 0;
    text-align: center;
    color: #DDDDDD;
    margin: auto;
    margin: 0;
  }
  
  .ProjectTileInfo h4 {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    max-width: 85vw;
  }

  .row {
    margin: 0;
    margin-top: 3rem;
    padding: 0;
    width: 100vw;
  }

  .ProjectTile:active .ProjectTileImage{
    opacity: 0.1;
  }
  
  .ProjectTile:active .ProjectTileInfo {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .ProjectTile {
    position: relative;
    height: 11rem;
    max-width: 85vw;
    margin-left: 2.5vw;
    margin-bottom: 4rem;
  }

  .ProjectTileImage {
    opacity: 1;
    width: 85vw;
    height: auto;
    transition: .5s ease;
    transform: translate(0, -8.5rem);
    -ms-transform: translate(0, -8.5rem); 
    margin: auto;
    margin-bottom: -35%;
    border: solid 1px #61dafb;
  }

  .ProjectTileInfo h4 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: .6rem;
    max-width: 85vw;
  }
}

@media only screen and (max-width: 365px) {
  .ProjectTileInfo h3 {
    font-size: .8rem;
    margin-bottom: 0;
  }
  .ProjectTileInfo h4 {
    padding-left: .6rem;
    padding-right: .6rem;
    font-size: .5rem;
    max-width: 85vw;
    margin-top: 0;
  }
}

/*Extracurricular Section CSS*/
.ExtracurricularTile {
  width: 100%;
  text-align: center;
  margin-top: 4rem;
  color: #DDDDDD;
}

.ExtracurricularTile img{
  width: 50rem;
  margin: auto;
}

.ExtracurricularTile h2 {
  font-family: Quicksand, sans-serif;
  font-weight: lighter;
}

/*Mobile Extracurricular Section CSS*/
@media only screen and (max-width: 900px) {
  .ExtracurricularTile {
    width: 85vw;
    text-align: center;
    margin-top: 4rem;
    color: #DDDDDD;
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }

  .ExtracurricularTile img{
    width: 85vw;
    margin: auto;
  }
}

.ExtracurricularSection {
  margin-bottom: 5rem;
}

/*Footer Section CSS*/

.App-footer {
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #393D45;
  min-height: 7rem;
}

.App-footer .copyright {
  flex: 3 1;
}

.App-footer h3{
  text-align: left;
  flex: 3 1;
  float: left;
  font-family: Montserrat,sans-serif;
  font-weight: 200;
  font-size: 1rem;
  color: #DDDDDD;
  position: absolute;
  bottom: 0;
  padding-left: 1rem;
}

.App-footer img {
  height: 2rem;
  width: 2rem;
  position: absolute;
  bottom: 0;
}

.App-footer ul {
  flex: 3 1;
  margin-right: 5rem;
  margin-left: -3rem;
  width: 100%;
  float: right;
  display: flex;
  justify-content: space-around;
}

.App-footer ul{
  max-width: 20rem;
  float: right;
  margin-right: 1rem;
}

.App-footer li {
  position: relative;
  list-style: none;
  float: left;
  margin-top: 3rem;
}

.App-footer li a {
  display: block;
  width: 100%;
  height: 1.5rem;
  padding: .7rem;
  border-bottom: 2 rem;
  font-size: 1.2rem;
  font-family: Source Sans Pro, sans-serif;
  color: #61dafb;
}

/*Footer Section CSS*/

@media only screen and (max-width: 600px) {
  .App-footer h3{
    text-align: left;
    flex: 3 1;
    float: left;
    font-family: Montserrat,sans-serif;
    font-weight: 200;
    font-size: .8rem;
    color: #DDDDDD;
    position: absolute;
    bottom: 0;
    padding-left: 1rem;
  }
  
  .App-footer img {
    height: 1.7rem;
    width: 1.7rem;
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .App-footer h3{
    text-align: left;
    flex: 3 1;
    float: left;
    font-family: Montserrat,sans-serif;
    font-weight: 200;
    font-size: .75rem;
    color: #DDDDDD;
    position: absolute;
    bottom: 0;
    padding-left: .8rem;
  }
  .App-footer img {
    height: 1.6rem;
    width: 1.6rem;
    position: absolute;
    bottom: 0;
  }
}